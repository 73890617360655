@import "bootstrap";

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
         transform: scale3d(1.05, 1.05, 1.05);
       }

  to {
    transform: scale3d(1, 1, 1);
  }
}

body {
      > *:not(.loadingIcon) {
        opacity: 1;
        @include transition(opacity .2s ease-out);
      }

      .loadingIcon {
        display: none;
      }

      &.loading {
            > *:not(.loadingIcon) {
              opacity: 0;
            }

            .loadingIcon {
              display: block;
              position: fixed;
              z-index: 99999;

              top: 50%;
              left: 50%;

              animation-duration: 1s;
              animation-fill-mode: both;
              animation-name: pulse;
              animation-iteration-count: infinite;

                @include media-breakpoint-down(sm) {
                    top: calc(50vh - 30px);
                    left: calc(50vw - 50px);
                }
            }


      }
}
